@import-normalize; /* bring in normalize.css styles */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Work+Sans:wght@700&display=swap');

@keyframes pulsate {
  0% {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
  50% {
    transform: scale(1.2) translate(-50%, -50%);
    opacity: 0.7;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
}

body {
  margin: 0;
  font-family: 'Roboto', 'sans-serif';
  font-weight: 500;
  font-size: 14px;
  line-height: 1.7;
}
p {
  font-weight: 700;
}
ul {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
button {
  cursor: pointer;
  padding: 0;
}
a {
  text-decoration: none;
}
